import { useDropEvent, useUserCampaignData } from 'root/src/client/logic/dropEvent/hooks/useDropEvent';
import { DarePlatform } from 'root/src/shared/@types/platforms';
import { useConnectedPlatformsByDares } from 'root/src/client/logic/dropEvent/hooks/useConnectedPlatformsByDares';
import { calculateMaxDaresByPlatform } from 'root/src/shared/util/calculateMaxDares';
import { useCreatorFollowersCountByPlatform, useUserCreatorData } from 'root/src/client/v2/common/hooks/useUser';
export function useIsMaxDaresReachedByPlatform({ platform = DarePlatform.Any, recordId }) {
    const dropEvent = useDropEvent(recordId);
    const userCampaignData = useUserCampaignData(recordId);
    const connectedPlatformsByDares = useConnectedPlatformsByDares(recordId);
    const { averageCcv } = useUserCreatorData();
    const followerCountByPlatform = useCreatorFollowersCountByPlatform();
    if (!dropEvent) {
        return false;
    }
    if (!connectedPlatformsByDares.length) {
        return false;
    }
    const platforms = (platform === DarePlatform.Any) ? connectedPlatformsByDares : [platform];
    return platforms.every((darePlatform) => {
        const maxDares = calculateMaxDaresByPlatform({
            platform: darePlatform,
            averageCcv: averageCcv || 0,
            followerCount: followerCountByPlatform[darePlatform],
            campaign: dropEvent,
        });
        if (!maxDares) {
            return true;
        }
        return isMaxDaresReachedByPlatform({ dropEvent, maxDares, platform: darePlatform, userCampaignData });
    });
}
export function useIsMaxDaresReachedByAllUserPlatforms(recordId) {
    return useIsMaxDaresReachedByPlatform({ platform: DarePlatform.Any, recordId });
}
export function isMaxDaresReachedByPlatform({ maxDares, dropEvent, platform, userCampaignData }) {
    var _a, _b, _c;
    const { dares, creatorAcceptedDaresByPlatform } = dropEvent;
    const userAcceptedDaresCount = (_c = (_a = creatorAcceptedDaresByPlatform === null || creatorAcceptedDaresByPlatform === void 0 ? void 0 : creatorAcceptedDaresByPlatform[platform]) !== null && _a !== void 0 ? _a : (_b = userCampaignData === null || userCampaignData === void 0 ? void 0 : userCampaignData.creatorAcceptedDaresByPlatform) === null || _b === void 0 ? void 0 : _b[platform]) !== null && _c !== void 0 ? _c : 0;
    const userMaxDares = Math.min(maxDares, dares.length);
    const isAnyDareAccepted = userAcceptedDaresCount > 0;
    return isAnyDareAccepted && (userMaxDares - userAcceptedDaresCount <= 0);
}
