import { withProjectCardStyle } from 'root/src/client/web/list/listItemComponents/style'
import { useDareCard } from 'root/src/client/web/list/listItemComponents/dare/hooks/useDareCard'
import classNames from 'classnames'
import * as BlackCard from 'root/src/client/v2/common/components/BlackCard/BlackCard'
import Typography, { typographyVariants } from 'root/src/client/web/typography/Typography'
import TextWithClickableLinks from 'root/src/client/web/base/TextWithClickableLinks'
import { colorsNew } from 'root/src/client/web/commonStyles'
import { brandProductTypesEnum } from 'root/src/shared/constants/dropEvent'
import format2Decimals from 'root/src/shared/util/format2Decimals'
import ClockIconWhite from 'root/src/client/public/icons/ic-clock-white.svg'
import { useState } from 'react'
import HintIcon from 'root/src/client/public/icons/ic-hint.svg'
import { useBlackCardContext } from 'root/src/client/v2/common/components/BlackCard/hooks/useBlackCardContext'
import ProgressBar from 'root/src/client/web/base/ProgressBar'
import {
	BaseTooltip,
	BaseTooltipContent,
} from 'root/src/client/v2/common/components/BaseTooltip/BaseTooltip'
import providers, { darePlatformsMap } from 'root/src/shared/constants/providers'
import { PlatformIcon, PlatformIconColor } from 'root/src/client/web/base/CustomButton/PlatformIcon'
import InfoIcon from '@mui/icons-material/Info'
import { BundleTooltipContent } from 'root/src/client/web/record/DropEvent/BundleTooltipContent'

const getRewardText = ({
	product,
	projectAssignees,
}) => {
	if (projectAssignees && product) return 'Reward:'
	if (product) return 'Reward for Fans:'
	return 'Total pledge'
}

export const RewardProgressBar = ({
	remaining,
	total,
}) => (
	<ProgressBar
		progress={(remaining / total) * 100}
		progressBackgroundColor={colorsNew.white}
		progressBarColor={colorsNew.pink}
		progressBackgroundStyle={{
			padding: 2,
			height: 12,
			boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
			width: '100%',
		}}
	/>
)

const RewardScalableIndicator = () => (
	<div>
		<BaseTooltip tooltip={(
			<BaseTooltipContent>Rewards scale based on your avg.
				CCV/Views
			</BaseTooltipContent>
		)}
		>
			<div className="flex flex-initial justify-center items-center h-5 w-5 rounded-full border border-primary hover:bg-primary motion-safe:duration-100">
				<span className="-translate-x-[0.05rem] text-xs italic select-none">i</span>
			</div>
		</BaseTooltip>
	</div>
)

const Reward = () => {
	const {
		dare: { product },
		extraData: {
			user: {
				isEligibleToEarnPoints,
				pointsToEarn,
			},
			dareCardContext: {
				projectAssignees,
			},
		},
	} = useDareCard()
	return (
		<div className="flex flex-initial justify-between gap-2 w-full">
			<div className="flex relative flex-initial flex-col flex-start w-1/2">
				<BlackCard.HighlightedSection
					title={(
						<div style={{
							display: 'flex',
							alignItems: 'center',
							gap: 4,
						}}
						>
							<Typography
								className="text-transform-none"
								variant={typographyVariants.mini}
								color={colorsNew.teal}
							>
								{getRewardText({
									projectAssignees,
									product,
								})}
							</Typography>
						</div>
					)}
					text={<RewardName />}
				/>
				{pointsToEarn !== undefined && isEligibleToEarnPoints && (
					<div>
						<Typography color={colorsNew.light3} variant={typographyVariants.micro}>
							Additionally you will earn
							<span style={{ color: colorsNew.white }}> {pointsToEarn}</span> points
						</Typography>
					</div>
				)}
				<DareRewardRemaining />
			</div>
			<div className="self-end">
				<DareRewardAmount />
			</div>
		</div>
	)
}

const EstimatedTime = withProjectCardStyle(({
	classes,
	time,
}) => (
	<BlackCard.HighlightedSection
		title={(
			<div className={classes.estimatedTimeContainer}>
				<img src={ClockIconWhite.src} alt="Clock" />
				<p className={classes.dareCardSectionTitle}>ESTIMATED TIME TO COMPLETE</p>
			</div>
		)}
		text={<Typography variant={typographyVariants.heading2}>{time}</Typography>}
	/>
))

const DareRewardRemaining = () => {
	const {
		dare: {
			capacity,
			product,
		},
	} = useDareCard()
	const remaining = Math.max(0, capacity.available)
	return (
		<div className="flex flex-col gap-1">
			<RewardProgressBar
				remaining={remaining}
				total={capacity.available + capacity.used}
			/>
			{product.type === brandProductTypesEnum.customProduct ? (
				<span className="capitalize font-medium text-xs text-light-4">Unlimited</span>
			) : (
				<span className="text-xs text-light-4">
					<span className="text-light-4 font-bold text-xl">{Number.isNaN(remaining) ? '?' : remaining}</span> <span className="capitalize font-medium text-light-4">remaining</span>
				</span>
			)}
		</div>
	)
}

const DareRewardAmount = () => {
	const {
		dare: {
			amount,
			isRewardScalable,
		},
	} = useDareCard()
	return (
		<div className="flex self-start flex-initial gap-2 justify-center">
			{isRewardScalable && (
				<RewardScalableIndicator />
			)}
			<div>
				<BlackCard.HighlightedSection
					className="flex flex-col justify-between gap-2"
					title={(
						<Typography
							className="uppercase"
							variant={typographyVariants.mini}
							color={colorsNew.teal}
						>
							Dare rewards
						</Typography>
					)}
					text={(
						<span
							className="text-center self-center flex justify-center items-center text-3xl font-bold font-mono"
						>
							{amount}
						</span>
					)}
				/>
			</div>
		</div>

	)
}

const DareHint = withProjectCardStyle(({
	classes,
	hint,
}) => {
	const [isRevealed, setIsRevealed] = useState(false)
	return (
		<div className={classes.dareHintContainer}>
			<div className={classes.dareHeaderSection}>
				<img src={HintIcon.src} alt="Hint" />
				<p className={classes.dareCardSectionTitle}>GAME HINT</p>
			</div>
			<p className={classNames(classes.dareHint, {
				[classes.blurred]: !isRevealed,
			})}
			>
				{hint}
			</p>
			{!isRevealed && (
				<button
					type="button"
					className={classes.revealHintButton}
					onClick={() => setIsRevealed(true)}
				>
					Click To Reveal &#x203A;
				</button>
			)}
		</div>
	)
})

const DareDetails = withProjectCardStyle(({ classes }) => {
	const {
		dare: {
			estimatedTime,
			hint,
			platform,
		},
	} = useDareCard()
	return (
		<div className={classNames(classes.cardDetails, classes.darePreview)}>
			<div className="flex flex-initial flex-col gap-2">
				<div className={classNames(classes.dareHeaderSection, classes.justifyBetween, 'gap-2')}>
					<div className="flex items-start flex-initial w-1/2">
						{estimatedTime && <EstimatedTime time={estimatedTime} />}
					</div>
					<DareRewardAmount />
				</div>
				{platform && (
					<div className="flex justify-between">
						<div>
							<RewardName />
						</div>
						<div className="flex flex-initial self-end">
							<DarePlatformsSection platforms={[platform]} />
						</div>
					</div>
				)}
			</div>
			{hint && <DareHint hint={hint} />}
		</div>
	)
})

const DarePreview = withProjectCardStyle(({ classes }) => {
	const {
		dare: {
			title,
			description,
			platform,
		},
	} = useDareCard()
	return (
		<div className={classNames(classes.cardPreview, classes.darePreview)}>
			<div>
				<BlackCard.Caption title={title} />
				<Typography
					className={classNames(classes.truncate, classes.dareDescription)}
					variant={typographyVariants.micro}
				>
					<TextWithClickableLinks text={description} />
				</Typography>
			</div>
			<DarePlatformsSection platforms={[platform]} />
			<Reward />
		</div>
	)
})

export const DarePlatformsSection = withProjectCardStyle(({ classes, platforms }) => {
	const darePlatforms = platforms?.includes(providers.any)
		? darePlatformsMap
		: platforms

	return (
		<ul className={classes.dropPlatforms}>
			{darePlatforms.map(platform => (
				<li
					key={platform}
					className={classes.platformIconContainer}
				>
					<PlatformIcon color={PlatformIconColor.Dark} size="xs" platform={platform} />
				</li>
			))}
		</ul>
	)
})

export const DareCardBody = () => {
	const {
		dare: {
			estimatedTime,
			hint,
		},
	} = useDareCard()
	const { isHovered } = useBlackCardContext()

	if (isHovered && (estimatedTime || hint)) {
		return <DareDetails />
	}

	return <DarePreview />
}

const RewardName = () => {
	const {
		dare: {
			product,
		},
		extraData: {
			isBundle,
			dareCardContext: { goalAmount, pledgeAmount, brandProduct },
		},
	} = useDareCard()
	return (
		<BaseTooltip
			tooltip={isBundle && <BundleTooltipContent rewards={product.rewards} />}
		>
			<div className="flex items-center gap-2">
				{isBundle && (
					<InfoIcon />
				)}
				<span className="block max-w-18 text-xs text-left break-words">
					{brandProduct
						? brandProduct.brandProductName
						: `$${format2Decimals(pledgeAmount)}${goalAmount ? ` of $${format2Decimals(goalAmount)}` : null}`}
				</span>
			</div>
		</BaseTooltip>
	)
}
